import type { User } from "@clerk/clerk-sdk-node";
import type { Tenant } from "@prisma/client";

import { useContext, useEffect } from "react";
import { type LoaderFunction, json } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { getAuth } from "@clerk/remix/ssr.server";

import { getUserById } from "~/data/users.server";

import Layout from "~/layout/Layout";
import MonarchyLogo from "~/components/MonarchyLogo";

import AppContext from "~/context";

export default function FourOFourPage() {
  const { user }: { user: User & Tenant } = useLoaderData<typeof loader>();
  const appContext = useContext(AppContext);
  if (!appContext) throw new Error("AppContext is null");

  useEffect(() => {
    appContext.setUser(user);
  }, [user, appContext]);

  return (
    <Layout hasNoNavbar={!user}>
      <div
        className={`container flex flex-col justify-center items-center mb-big-gutter ${
          user ? "min-h-[80vh]" : "min-h-[89vh]"
        }`}
      >
        <div className="flex flex-col gap-gutter">
          <MonarchyLogo />
          <h1 className="text-center text-4xl leading-3 mt-6">404</h1>
          <p className="text-small-title text-primary-500 font-normal">
            The requested page cannot be found.
          </p>
          <div className="flex justify-center gap-gutter">
            {user && user.id ? (
              <Link className="button w-max" to="/">
                Go back to home page
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const loader: LoaderFunction = async (args) => {
  const { userId } = await getAuth(args);

  let userObject = null;
  if (userId) userObject = await getUserById(userId);

  return json({ user: userObject }, { status: 404 });
};

export const action = async () => {
  return json({}, { status: 404 });
};
